// Default imports
import React from "react";
import { Row, Col } from "react-bootstrap";

import WpImage from "../components/WpImage";
import ButtonText from "./ButtonText";

const Locations = ({ locations }) => {
  return (
    <div className="locations">
      {locations.map((location, index) => (
        <LocationItem key={index} {...location} />
      ))}
    </div>
  );
};

const LocationItem = ({ image, header, title, address, phone, links }) => (
  <div className="locations__item">
    <Row className="align-items-center flex-column-reverse flex-lg-row">
      <Col lg="12">
        <div className="locations__item">
          {header && <h3 className="locations__item-title">{header}</h3>}
          {title && <h4 className="locations__item-name">{title}</h4>}
          {address && (
            <div
              className="locations__item-text"
              dangerouslySetInnerHTML={{ __html: address }}
            />
          )}
          {phone && <p className="locations__item-text -phone">{phone}</p>}
        </div>

        {links.map(({ link }, index) => (
          <ButtonText
            className="locations__link"
            variant="red,slim"
            key={index}
            {...link}
          >
            {link?.title}
          </ButtonText>
        ))}
      </Col>
      {image && (
        <Col lg={{ span: 10, offset: 0 }} xl={{ span: 8, offset: 2 }}>
          <WpImage image={image} className="locations__image" />
        </Col>
      )}
    </Row>
  </div>
);

export default Locations;
export { LocationItem };
