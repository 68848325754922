import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { contactPageTplData } from "../data/page-tpl-contact.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import PageHead from "../components/Page/PageHead";
import Locations from "../components/Locations";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";

const pageContact = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = contactPageTplData(id);

  return (
    <Layout>
      {/* SEO Metas */}
      <Seo post={seoPageData} />

      {/* Page head */}
      <Container>
        <PageHead
          title={pageData.pageHeader.header}
          text={pageData.pageHeader.text}
        />
      </Container>

      {/* Resources Section */}
      <Section className="-small-margin-bottom">
        <Container>
          <Locations locations={pageData.contacts} />
        </Container>
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default pageContact;
