import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const contactPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Contact {
                  contactRichContent {
                    # SECTION: Page Header
                    pageHeader {
                      header
                      text
                    }

                    # SECTION: Contacts
                    contacts {
                      header
                      title
                      address
                      phone
                      links {
                        link {
                          target
                          title
                          url
                        }
                      }
                      image {
                        altText
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData
                          }

                          childSvg {
                            content {
                              data
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "contactRichContent");
};

export { contactPageTplData };
